<template>
  <div>
    <b-card>
      <ValidationObserver ref="user" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <!-- <b-col md="6">
            <input-form v-model="user.translations[0].name" :name="$t('users.nameAr')" :label="$t('users.nameAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="user.translations[1].name" :name="$t('users.nameEn')" :label="$t('users.nameEn')" validate="required" :disabled="disableStatus" />
          </b-col> -->

          <b-col md="6">
            <input-form v-model="user.username" :name="$t('global.userName')" :label="$t('global.userName')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="user.phone" :name="$t('global.phone')" :label="$t('global.phone')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="12">
            <input-form v-model="user.email" :name="$t('global.email')" :label="$t('global.email')" validate="required|email" :disabled="disableStatus" />
          </b-col>
          <!-- <b-col md="6">
            <date-time-picker v-model="user.birth_date" :name="$t('global.birthDate')" :label="$t('global.birthDate')" validate="required" :disabled="disableStatus" />
          </b-col> -->

          <!-- <b-col md="6">
            <main-select :options="GENDERS" label="name" v-model="user.gender" :reduce="i => i.id" :text="user.gender" :labelTitle="$t('global.gender')" validate="required" :showAsInfo="disableStatus" />
          </b-col> -->
          <!-- <b-col md="6">
            <main-select :options="MARITAL_STATUS" label="name" v-model="user.marital_status" :reduce="i => i.id" :text="user.marital_status" :labelTitle="$t('global.maritalStatus')" validate="required" :showAsInfo="disableStatus" />
          </b-col> -->

          <b-col md="6" v-if="currentPage === itemPages.create">
            <input-form v-model="user.password" :name="$t('global.password')" :label="$t('global.password')" validate="required" :disabled="disableStatus" type="password" />
          </b-col>
          <b-col md="6" v-if="currentPage === itemPages.create">
            <input-form v-model="user.confirm_password" :name="$t('global.confirmPassword')" :label="$t('global.confirmPassword')" validate="required" :disabled="disableStatus" type="password" />
          </b-col>

          <b-col md="12" v-if="currentPage != itemPages.view">
            <main-select :multiple="true" :options="roles" label="name" v-model="user.rolesId" :reduce="i => i.id" text=" " :labelTitle="$t('roles.items')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
        </b-row>
        <validation-provider vid="activated" name="activated" rules="required" v-slot="{ errors }">
            <b-form-group :label="$t('users.userActivation')"
            >
              <b-form-radio v-model="user.activated" :value="true" >{{ $t('global.activated') }}</b-form-radio>
              <b-form-radio v-model="user.activated" :value="false">{{ $t('global.deactivated') }}</b-form-radio>
            </b-form-group>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
      </validation-provider>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view && user.roles.length > 0">
      <roles :items="user.roles" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import usersServices from '../services/users'
import rolesService from '../../roles/services/roles'
import Roles from '../../roles/views/Roles.vue'
import User from '../models/User'
// import { GENDERS, MARITAL_STATUS } from '../../../../config/constantLists'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Roles },
  data () {
    return {
      user: {
        ...new User(),
        activated: true
      },
      // GENDERS,
      // MARITAL_STATUS,
      roles: []
    }
  },
  methods: {
    async create () {
      usersServices.create(this.user).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'administration.users' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      usersServices.update(this.id, this.user).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'administration.users' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      usersServices.findOne(this.id).then(response => {
        this.user.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (!this.disableStatus) {
      rolesService.findAll().then((res) => {
        this.roles = res.data.data
      })
    }
  }
}
</script>
