export default class User {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.image = null
    this.profile_photo_path = ''
    this.email = ''
    this.phone = ''
    this.username = ''
    this.password = ''
    this.confirm_password = ''
    this.birth_date = ''
    this.gender = ''
    this.marital_status = ''
    this.rolesId = []
    this.roles = []
    this.activated = ''
    // this.translations = [
    //   { id: null, name: '', lang: 'ar' },
    //   { id: null, name: '', lang: 'en' }
    // ]
  }

  fillData (data) {
    if (data) {
      // const itemAr = data.translations?.find(o => o.lang === 'ar')
      // const itemEn = data.translations?.find(o => o.lang === 'en')

      this.profile_photo_path = data.profile_photo_path ? data.profile_photo_path : ''
      this.email = data.email ? data.email : ''
      this.phone = data.phone ? data.phone : ''
      this.username = data.username ? data.username : ''
      this.password = data.password ? data.password : ''
      this.confirm_password = data.confirm_password ? data.confirm_password : ''
      this.birth_date = data.birth_date ? data.birth_date : ''
      this.gender = data.gender ? data.gender : ''
      this.marital_status = data.marital_status ? data.marital_status : ''
      this.rolesId = data.roles ? data.roles.map(i => i.id) : []
      this.roles = data.roles ? data.roles : []
      this.activated = data.activated ? data.activated : ''
      // this.translations[0].id = itemAr ? itemAr.id : null
      // this.translations[0].name = itemAr ? itemAr.name : ''
      // this.translations[1].id = itemEn ? itemEn.id : null
      // this.translations[1].name = itemEn ? itemEn.name : ''
    } else {
      this.setInitialValue()
    }
  }
}
