import { singleSignOn } from '@/axios'

export default {
  findAll () {
    return singleSignOn().get('admin')
  },
  findOne (id) {
    return singleSignOn().get(`admin/admin/${id}`)
  },
  create (data) {
    return singleSignOn().post('admin/admin/add', data)
  },
  update (id, data) {
    return singleSignOn().patch(`admin/admin/${id}`, data)
  },
  remove (id) {
    return singleSignOn().delete(`admin/admin/${id}`)
  }
}
