import api from "@/axios";

export default {
  findAll() {
    return api().get("admin/role");
  },
  findOne(id) {
    return api().get(`admin/role/${id}`);
  },
  create(data) {
    return api().post("admin/role/add", data);
  },
  update(id, data) {
    return api().patch(`admin/role/${id}`, data);
  },
  remove(id) {
    return api().delete(`admin/role/${id}`);
  },
  getSystemPermissions() {
    return api().get("admin/permission/get-all-permissions");
  },
  updatePermission(roleId, data) {
    return api().patch(`admin/permission/${roleId}`, data);
  },
};
